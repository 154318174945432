const headerWords = [
    {
        text: "What",
        background: "https://garden.spoonflower.com/c/11484029/p/f/m/LS1zJPi3CknGvS3JBMPeYF2kI3LjOL9RXrPXrVs0c-Gj73tJrJnT/Wild%20grasses%20.jpg"
    },
    {
        text: "Fabric",
        background: "https://garden.spoonflower.com/c/8834187/p/f/m/_7TaU-8p8UVejrUA3aRRgwiDpjGwG2VLcqYo9GIDzKF_VQBZBlQ/Mid%20Century%20Kaleidoscope.jpg"
    },
    {
        text: "Are",
        background: "https://garden.spoonflower.com/c/9792368/p/f/m/etadACe56aBNOENgXwA6zpNUZLE03JUNWO4kFY_0Zz5vQl-5Jjg/japanese%20waves.jpg"
    },
    {
        text: "You?",
        background: "https://garden.spoonflower.com/c/6545640/p/f/m/79h29Fh6yvXWsbr4edkAdvOiiuwZAqQmtq9m8IbdZfRFm2_hqqCe-ASnGW7GxA/Southdown%20tartan%20-%206%22%20tan%2Fblack%2Fwhite.jpg"
    }
]

export default headerWords; 